(function ($) {
    var fullPage;
    var dyna_cal_js = '//websdk.fastbooking-services.com/widgets/app.js',
        dyna_cal_css = '//websdk.fastbooking-services.com/widgets/app.css';

    function isLoadedScript(lib) {
        return document.querySelectorAll('[src="' + lib + '"]').length > 0
    }

    function isLoadedCss(lib) {
        return document.querySelectorAll('[href="' + lib + '"]').length > 0
    }

    function loadScript(lib) {
        var script = document.createElement('script');
        script.setAttribute('src', lib);
        document.getElementsByTagName('head')[0].appendChild(script);
        return script;
    }

    function loadStyle(lib) {
        var link = document.createElement('link');
        link.setAttribute('href', lib);
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type','text/css');
        document.getElementsByTagName('head')[0].appendChild(link);
        return link;
    }

    function grand_magic_refresh_size_queries() {
        var classes = [];
        var scrollbarwidth = grand_magic_getScrollbarWidth();
        window_width = $(window).width() + scrollbarwidth;
        window_height = $(window).height();
        window.is_phone = (window_width < 768);
        window.is_mobile = (window_width < 992);
        window.is_tablet_portrait = (window_width >= 768 && window_width < 992);
        window.is_tablet_landscape = (window_width >= 992 && window_width < 1200 && window_height <= 768);
        window.is_tablet = is_tablet_portrait || is_tablet_landscape;
        window.is_desktop = (window_width >= 992);
        window.is_desktop_large = (window_width >= 1200);

        if (window.is_phone) {
            classes.push('mq_phone');
        }
        if (window.is_mobile) {
            classes.push('mq_mobile');
        }
        if (window.is_tablet_portrait) {
            classes.push('mq_tablet_portrait');
        }
        if (window.is_tablet_landscape) {
            classes.push('mq_tablet_landscape');
        }
        if (window.is_tablet) {
            classes.push('mq_tablet');
        }
        if (window.is_desktop) {
            classes.push('mq_desktop');
        }
        if (window.is_desktop_large) {
            classes.push('mq_desktop_large');
        }


        $('html').removeClass('mq_phone');
        $('html').removeClass('mq_mobile');
        $('html').removeClass('mq_tablet_portrait');
        $('html').removeClass('mq_tablet_landscape');
        $('html').removeClass('mq_tablet');
        $('html').removeClass('mq_desktop');

        $('html').addClass(classes.join(' '));

    }

    function grand_magic_getScrollbarWidth() {
        var outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.width = "100px";
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);
        var widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = "scroll";
        // add innerdiv
        var inner = document.createElement("div");
        inner.style.width = "100%";
        outer.appendChild(inner);
        var widthWithScroll = inner.offsetWidth;
        // remove divs
        outer.parentNode.removeChild(outer);
        return widthNoScroll - widthWithScroll;
    }

    function grand_magic_remove_loader(){
        var loader = $('.loader');

        loader.hide();
        $('html, body').toggleClass('has-loader', false);
        if($('html').hasClass('home') && !is_mobile){
            if(fullPage){
                fullpage_api.setAllowScrolling(true);
                fullpage_api.setKeyboardScrolling(true);
            }
        }

    }

    function grand_magic_sidebar_menu() {
        var hamburger_hotel = $('.open_menu'),
        sidebar_wrap = $('.menu-sidebar-wrap');

        if (hamburger_hotel.length > 0) {
            var close_btn = $('.close-container');
            hamburger_hotel.on('click', function () {
                sidebar_wrap.addClass('opened');
                setTimeout(function () {
                    $('.menu-sidebar-wrap').addClass('visible');
                }, 300);
                $('html').addClass('no-scroll');
                if($('html').hasClass('home')){
                    if(fullPage){
                        fullpage_api.setAllowScrolling(false);
                        fullpage_api.setKeyboardScrolling(false);
                    }
                }
            });

            if ($('#primary_menu').length > 0) {
                var items = $('#primary_menu .menu').find('li');

                items.each(function () {
                    var item = $(this);
                    if (item.hasClass('menu-item-has-children')) {
                        var dropdown = item.find('.dropdown');

                        dropdown.on('click', function () {
                            if (!item.hasClass('sub-menu-opened')) {
                                item.addClass('sub-menu-opened');
                            } else {
                                item.removeClass('sub-menu-opened');
                            }
                        });
                    }
                });
            }
            sidebar_wrap.on('webkitTransitionEnd oTransitionEnd transitionend msTransitionEnd', function(){
                if(sidebar_wrap.hasClass('visible')){
                    $('.menu-sidebar-overlay').on('click',function(){
                        sidebar_wrap.removeClass('visible');
                        setTimeout(function () {
                            sidebar_wrap.removeClass('opened');
                        }, 300);

                        $('html').removeClass('no-scroll');
                        if($('html').hasClass('home')){
                            if(!is_mobile){
                                if(fullPage){
                                    fullpage_api.setAllowScrolling(true);
                                    fullpage_api.setKeyboardScrolling(true);
                                }
                            }
                        }
                    });
                }
            });

            close_btn.on('click', function () {
                sidebar_wrap.removeClass('visible');
                setTimeout(function () {
                    sidebar_wrap.removeClass('opened');
                }, 300);

                $('html').removeClass('no-scroll');
                if($('html').hasClass('home')){
                    if(!is_mobile){
                        if(fullPage){
                            fullpage_api.setAllowScrolling(true);
                            fullpage_api.setKeyboardScrolling(true);
                        }
                    }
                }
            });
        }
    }

    function grand_magic_header_position() {
        var header = document.getElementsByClassName('header-wrapper'),
            scroll = $(window).scrollTop(),
            breakpoint = header[0].offsetHeight;
        if (scroll >= breakpoint) {
            header[0].classList.add('fixed');
        } else {
            header[0].classList.remove('fixed');
        }
    }

    function grand_magic_header_logo(){
        var big_logo = document.getElementById('logo_big');
        if(big_logo){
            gsap.to('.logo-big',{
                scrollTrigger: {
                    trigger: ".header-wrapper",
                    start: "top top",
                    end: "bottom top",
                    scrub: true
                },
                duration: 1,

                yPercent: -100,
                bottom: 100,
                top: "auto",
            });
        }
    }

    function grand_magic_slideshow() {
        if(!$('html').hasClass('error404')){
            var slideshow_container = document.getElementById('slideshow_wrapper');
            if (slideshow_container) {
                var slideshow = new Swiper('.slideshow-wrapper', {
                    loop: true,
                    effect: 'fade',
                    speed: 4500,
                    autoplay: true,
                    fadeEffect: {
                        crossFade: true
                    },
                    disableOnInteraction: false,
                    waitForTransition: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                });
                slideshow.on('slideChangeTransitionStart', function(){
                    var slideToPreloadPicture = $('.swiper-slide').find('picture');
                    $(slideToPreloadPicture).each(function () {
                        var slideToPreloadSourceSets = $(this).find('source');
                        var slideToPreloadImg = $(this).find('img');
                        if (slideToPreloadImg.data('load') === 'no-loaded') {

                            for (var i = 0; i < slideToPreloadSourceSets.length; i++) {
                                if (slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset') != null) {
                                    slideToPreloadSourceSets[i].setAttribute('srcset', slideToPreloadSourceSets[i].getAttribute('data-lazy-srcset'));
                                    slideToPreloadSourceSets[i].removeAttribute('data-lazy-srcset');
                                }
                            }

                            for (var i = 0; i < slideToPreloadImg.length; i++) {
                                if (slideToPreloadImg[i].getAttribute('data-lazy-src')) {
                                    slideToPreloadImg[i].setAttribute('src', slideToPreloadImg[i].getAttribute('data-lazy-src'));
                                    slideToPreloadImg[i].removeAttribute('data-lazy-src');
                                }
                            }

                            slideToPreloadImg.attr('data-load', 'loaded');
                        } else {
                            return;
                        }

                    });
                });
            }
        }
    }

    function grand_magic_video_player(){
        var video_wrapper = document.getElementsByClassName('video-wrapper');

        if(video_wrapper[0]){
            if($('#player').length > 0){
                var player = new Plyr('#player',{
                    debug: false,
                    controls: ['play-large', 'play', 'progress', 'mute', 'volume', 'fullscreen'],
                    autoplay: (is_phone || is_mobile) ? false : video_autoplay,
                    volume: (video_autoplay === false && is_phone || is_mobile) ? 1 : (video_autoplay) ? 0 : 1,
                    muted: (video_autoplay === false && is_phone || is_mobile) ? false : (video_autoplay) ? true : false,
                    storage:{
                        enabled: false,
                    },
                    loop: { active: video_loop }
                });

                player.on('ready', function(){
                    if(!$('html').hasClass('has-loader')){
                        if(video_autoplay){
                            player.play();
                        }
                    }
                });
            }

        }
    }

    function grand_magic_dynamic_calendar_lazy(){
        if (!isLoadedScript(dyna_cal_js)) {
            loadScript(dyna_cal_js);
        }
        if (!isLoadedCss(dyna_cal_css)) {
            loadStyle(dyna_cal_css);
        }
    }

    function grand_magic_update_calendar_config(config, event){
        if( !event ){
            config.params[0].calendar.roomFilter = '';
            config.params[0].calendar.roomRateFiltering = 0;
        }
        else {
            config.params[0].calendar.roomFilter = [$(event.currentTarget).data('room')];
            config.params[0].calendar.roomRateFiltering = 2;
        }

        return config;
    }

    function grand_magic_dynamic_calendar() {
        var btns = '#open_dyna_cal, #open_mobile_dyna_cal, .calendar';
        var calendar_wrapper = $('#dyncal_wrapper');
        $(document).on( 'mouseenter touchstart', btns, function (event) {
            grand_magic_dynamic_calendar_lazy();
        })

        $(document).on( 'click', btns, function (event) {
            setTimeout(function(){
                if( $(event.currentTarget).data('room') ){

                    var configElmt = document.querySelector('.fb-widget-config');
                    var config = JSON.parse(configElmt.innerText);
                    config = grand_magic_update_calendar_config(config, event);
                    configElmt.innerText = JSON.stringify(config);
                    if (window.loadFBWidgets) {
                        window.loadFBWidgets();
                    }
                    
                }
                else {
                    
                    var configElmt = document.querySelector('.fb-widget-config');
                    var config = JSON.parse(configElmt.innerText);
                    config = grand_magic_update_calendar_config(config, false);
                    configElmt.innerText = JSON.stringify(config);
                    if (window.loadFBWidgets) {
                        window.loadFBWidgets();
                    }
                    
                }

                if (!calendar_wrapper.hasClass('visible')) {
                    calendar_wrapper.addClass('visible');
                    $('html').addClass('no-scroll');
                }
            });

        });

        $('.dyncal_overlay').on('click', function(){
            if (calendar_wrapper.hasClass('visible')) {
                $('.close_calendar').trigger('click');
            }
        });

        $('.close_calendar').click(function () {
            if (calendar_wrapper.hasClass('visible')) {
                calendar_wrapper.removeClass('visible');
                $('html').removeClass('no-scroll');
            }
        });
    }

    function grand_magic_minigallery_home(){
        if($('html').hasClass('home')){
            var minigallery_home = $('.minigallery-wrapper');
            if(minigallery_home.length > 0){
                var minigallery_home_container = new Swiper('.minigallery-container', {
                    slidesPerView: 1,
                    speed: 500,
                    init: is_phone,
                    disableOnInteraction: false,
                    waitForTransition: false,
                    navigation: {
                        nextEl: '.minigallery-next',
                        prevEl: '.minigallery-prev',
                    },
                });
            }
        }
    }

    function grand_magic_websdk_offers() {
        $fbsdk.Config.baseHost = 'websdk.fastbooking-services.com';
        $('div[data-websdk]').each(function () {
            var container = $(this),
                model_name = container.data('websdk'),
                id = container.attr('id'),
                custom_template = container.data('websdk-template'),
                template_id, config, model, template, html;
            template_id = custom_template ? custom_template : '#' + id + '_template';
            template = $(template_id).text();
            if (typeof websdk_config !== 'undefined' && typeof websdk_config[id] !== 'undefined' /*&& typeof $fbsdk !== "undefined"*/) {
                config = websdk_config[id];
                $fbsdk.baseHost = config.baseHost;
                model = new $fbsdk[model_name](config.params);
                model.setAuthToken(config._authCode);
                model.load(function (error) {
                    var data, i;
                    if (!error) {
                        //YEAH, this happens when we have data and we're ready to use it!
                        data = this.getRawData();
                        if (model_name === 'Offers' && data.rates.length === 0) {
                            container.addClass('no-offers');
                        }
                        container.closest('.loading').addClass(' processed no-loading');

                        //Limit numbers of offers to display
                        if (typeof config.offers_number !== 'undefined' && config.offers_number <= data.rates.length) {
                            data.rates.length = parseInt(config.offers_number, 10);
                        }

                        // Show only offers want to display works only on preview offers section
                        if (typeof config.onlyToShow !== 'undefined' && config.onlyToShow.length > 0) {
                            var temp_rates = [];
                            for (var i = 0; i < data.rates.length; i++) {
                                if (typeof data.rates[i] !== 'undefined') {
                                    if ($.inArray(data.rates[i].rate.name, config.onlyToShow) !== -1) {
                                        // if is allowed
                                        temp_rates.push(data.rates[i]);
                                    }
                                }
                            }
                            // OVERRRIDE THE OBJ
                            data.rates = temp_rates;
                        } else {
                            //Exclude offers from websdk
                            if (typeof config.excluded_offers !== 'undefined' && config.excluded_offers.length > 0) {
                                var temp_rates = [];
                                for (var i = 0; i < data.rates.length; i++) {
                                    if (typeof data.rates[i] !== 'undefined') {
                                        if ($.inArray(data.rates[i].rate.name, config.excluded_offers) === -1) {
                                            // if is allowed
                                            temp_rates.push(data.rates[i]);
                                        }
                                    }
                                }
                                // OVERRRIDE THE OBJ
                                data.rates = temp_rates;
                            }
                        }

                        // Force Hotel-vente-flash-FL1 to be the first offer to show
                        data.rates.sort(function (a, b) {
                            if( a.rate.name === 'Hotel-vente-flash-FL1'){
                                return -1;
                            }
                            else if( b.rate.name === 'Hotel-vente-flash-FL1'){
                                return 1;
                            }

                            return 0;
                        });


                        for (var i = 0; i < data.rates.length; i++) {
                            data.rates[i].rate.title = data.rates[i].rate.title.replace(/(<([^>]+)>)/ig, '').toLowerCase();
                            data.rates[i].rate.shortdescription = data.rates[i].rate.plain_description.substr(0, 120) + '...';

                            var rateTotalPrice = data.rates[i].quotation.totalPrice,
                                rateTotalPriceParts = rateTotalPrice.toString().split('.',2),
                                totalPriceWithDecimals = '';

                            if(rateTotalPriceParts.length === 2){
                                var decimals = (rateTotalPriceParts[1].length === 1) ? rateTotalPriceParts[1] + '0' : rateTotalPriceParts[1];
                                totalPriceWithDecimals = rateTotalPriceParts[0] + '.' + decimals;
                            } else {
                                totalPriceWithDecimals = rateTotalPrice.toString().toString() + '.00';
                            }

                            data.rates[i].quotation.totalPriceWithDecimals = totalPriceWithDecimals;
                        }


                        html = Mustache.render(template, data);
                        container.html(html);

                        //Update lazyload images
                        lazyLoadInstance.update();
                        grand_magic_sparkle_callback();
                        grand_magic_preview_offers_carousel();

                    } else {
                        // in caso di errore
                        container.fadeOut();
                    }
                });
            }
        });
    }

    function grand_magic_preview_offers_carousel(){
        var preview_offers_wrapper = document.getElementById('preview_offers_wrapper');

        if (preview_offers_wrapper) {
            var single_offer = preview_offers_wrapper.getElementsByClassName("single-offer"),
                hasCarousel = false;
            equalHeights('single-offer-content');
            if(is_mobile){
                if(single_offer.length > 1){
                    hasCarousel = true;
                    preview_offers_wrapper.classList.remove('no-carousel');
                } else {
                    preview_offers_wrapper.classList.add('no-carousel');
                }
            } else {
                if(single_offer.length > 3){
                    hasCarousel = true;
                    preview_offers_wrapper.classList.remove('no-carousel');
                } else{
                    preview_offers_wrapper.classList.add('no-carousel');
                    if(single_offer.length === 3){
                        preview_offers_wrapper.classList.add('three-offers');
                    } else{
                        preview_offers_wrapper.classList.remove('three-offers');
                    }
                }
            }

            if(hasCarousel){
                var preview_carousel_offers = new Swiper('.preview-offers-wrapper', {
                    slidesPerView: 'auto',
                    centeredSlides: true,
                    loop: true,
                    disableOnInteraction: false,
                    waitForTransition: false,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 'auto',
                        },
                        992: {
                            slidesPerView: 3,
                        },
                    }
                });
            }
        }

    }

    function equalHeights(className) {
        var findClass = document.getElementsByClassName(className);

        var tallest = 0;
        // Loop over matching divs
        for(i = 0; i < findClass.length; i++) {
            var ele = findClass[i];
            var eleHeight = ele.offsetHeight;
            tallest = (eleHeight>tallest ? eleHeight : tallest); /* look up ternary operator if you dont know what this is */
        }
        for(i = 0; i < findClass.length; i++) {
            findClass[i].style.height = tallest + "px";
        }
    }

    function grand_magic_initialize_map(callback){
            if (typeof(div) === "undefined") div = "map_canvas";
            if (typeof(bounds) === "undefined" && map_config.set_bounds) bounds = new google.maps.LatLngBounds();

            var marker, i;

            var infoWindow = new google.maps.InfoWindow({
                'maxWidth': 500,
                'maxHeight': 400,
            });

            center = [map_config.markers[0].latitude, map_config.markers[0].longitude];

            map = new google.maps.Map(document.getElementById(div), {
                // scroll wheel
                scrollwheel: false,
                //zoom
                zoom: map_config.map_zoom,
                zoomControl: (is_phone) ? false : map_config.map_zoom_control,
                zoomControlOptions: {
                    position: google.maps.ControlPosition[map_config.zoom_control_position]
                },
                //position
                center: new google.maps.LatLng(center[0], center[1]),
                //map type
                mapTypeId: google.maps.MapTypeId[map_config.map_type],
                mapTypeControl: (is_phone) ? false : map_config.map_type_control,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    style: google.maps.MapTypeControlStyle.DEFAULT
                },
                // default pan control
                panControl: false,
                panControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // scale control - image with the scale index (m,km,etc)
                scaleControl: false,
                scaleControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                // 	streetview controls
                streetViewControl: (is_phone) ? false : map_config.map_streetview_control,
                streetViewControlOptions: {
                    position: google.maps.ControlPosition.LEFT_TOP
                },
                fullscreenControl: (!is_phone)

            });

            var styles = [
                    {
                        "featureType": "poi.business",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    },
                    {
                        "featureType": "poi.government",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    },
                    {
                        "featureType": "poi.medical",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    },
                    {
                        "featureType": "poi.place_of_worship",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    },
                    {
                        "featureType": "poi.school",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    },
                    {
                        "featureType": "poi.sports_complex",
                        "stylers": [
                            { "visibility": "off" }
                        ]
                    }
                ];
            map.setOptions({styles: styles});

            for (i = 0; i < map_config.markers.length; i++) {

                if (i === 0) {
                    marker = new google.maps.Marker({
                        position: new google.maps.LatLng(map_config.markers[0].latitude, map_config.markers[0].longitude),
                        map: map,
                        icon: map_config.markers[0].icon_hotel,
                        zIndex:99999999
                    });

                    google.maps.event.addListener(marker, 'click', (function (marker, i) {
                        infoWindow.close(map, marker);
                    })(marker, i));
                }
            }
    }

    function grand_magic_handler_map(){
            var map_container = document.querySelector('.map-container');
            if(map_container){
                observer = new IntersectionObserver(function(entry, observer){
                    if(entry[0].intersectionRatio > 0){
                        grand_magic_initialize_map();
                        observer.unobserve(map_container);
                    }
                });
                observer.observe(map_container);
            }
    }

    function grand_magic_siblings_room_carousel(){
        var siblings_rooms = document.getElementById('siblings_rooms_carousel');
        if(siblings_rooms){
            var siblings_rooms_carousel = new Swiper('.siblings-rooms-carousel', {
                slidesPerView: 1,
                speed: 500,
                disableOnInteraction: false,
                waitForTransition: false,
                navigation: {
                    nextEl: '.swiper-button-next-outside-siblings',
                    prevEl: '.swiper-button-prev-outside-siblings',
                },
                pagination: {
                    el: ".swiper-pagination-outside-siblings",
                    clickable: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                    },
                    1024: {
                        slidesPerView: 2,
                    },
                    1260: {
                        slidesPerView: 3,
                    }
                }
            });
        }
    }


    function grand_magic_room_minigallery(){
        var room_minigallery_container = document.getElementById('room_minigallery');


        if(room_minigallery_container){
            var single_image = room_minigallery_container.getElementsByClassName("single-image");

            if(is_phone){
                if(single_image.length > 1){
                    room_minigallery_container.classList.remove('carousel-centered');
                } else {
                    room_minigallery_container.classList.add('carousel-centered');
                }
            } else if(is_mobile){
                if(single_image.length > 2){
                    room_minigallery_container.classList.remove('carousel-centered');
                } else {
                    room_minigallery_container.classList.add('carousel-centered');
                }
            } else {
                if(single_image.length < 5){
                    room_minigallery_container.classList.add('carousel-centered');
                } else {
                    room_minigallery_container.classList.remove('carousel-centered');
                }
            }

            var room_minigallery = new Swiper('.room-minigallery', {
                slidesPerView: 'auto',
                speed: 500,
                disableOnInteraction: false,
                waitForTransition: false,
                loop: (single_image.length >= 5) ? true : false,
                centeredSlides: true,
                centeredSlidesBounds: (single_image.length < 5),
                navigation: {
                    nextEl: '.swiper-button-next-outside-minigallery',
                    prevEl: '.swiper-button-prev-outside-minigallery',
                },
                pagination: {
                    el: ".swiper-pagination-outside-minigallery",
                    clickable: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        loop: false,
                        centeredSlides: false,
                        centeredSlidesBounds: false
                    },
                    1024: {
                        slidesPerView: 3,
                        loop: false,
                        centeredSlides: false,
                        centeredSlidesBounds: false
                    },
                    1260: {
                        slidesPerView: 4,
                        loop: false,
                        centeredSlides: false,
                        centeredSlidesBounds: false
                    },
                    1400: {
                        slidesPerView: 5,
                        loop: false,
                        centeredSlides: false,
                        centeredSlidesBounds: false
                    },
                }
            });
        }
    }

    function grand_magic_manual_fancybox() {
        $('a[data-manual-fancy]').on('click',function () {
            var button = $(this);
            var data = button.data('src');
            if (!data) {
                return;
            }
            var html = $(data).text();
            $.fancybox.open(html, {
                'afterShow': function () {
                    grand_magic_sparkle_callback();
                    if (typeof renderRecaptcha !== 'undefined') {
                        renderRecaptcha();
                    }
                },
                autoFocus: false,
                arrows: false,
                infobar: false
            })
        })
    }

    function grand_magic_directions_carousel(){
        var directions_container = document.getElementById('directions_list_wrapper');
        if(directions_container){
            var directions_navigation = new Swiper('.directions-navigation-wrapper',{
                speed: 400,
                slidesPerView: 'auto',
            });
            var directions_list = new Swiper('.directions-list-wrapper', {
                slidesPerView: 1,
                speed: 400,
                disableOnInteraction: false,
                waitForTransition: false,
                thumbs: {
                    swiper: directions_navigation,
                    autoScrollOffset: 0,
                },

            });
        }
    }

    function grand_magic_photogallery(){
        var gallery_container = $('.photogallery-wrap');
        if(gallery_container.length > 0){
            gallery_container.isotope({
                itemSelector: '.single-image',
                layoutMode: 'fitRowsCentered',
            });

        $('.photogallery-filter .button').on( 'click', function() {
                var filterValue = $(this).attr('data-filter');
                gallery_container.isotope({ filter: (filterValue === 'filter_all') ? "*" : filterValue });
            });
        }
        $('.photogallery-filter-mobile select').on('change', function(){
            var filterValue = $(this).val();
            gallery_container.isotope({ filter: (filterValue === 'filter_all') ? "*" : filterValue });
        });
    }

    function grand_magic_news_hub(){
        var news_container = $('.all-news-wrapper'),
            pagination = $('.pagination-manager');
        if(news_container.length > 0){
            equalHeights('single-news');
            news_container.isotope({
                itemSelector: 'none',
                masonry: {
                    columnWidth: '.single-news-wrap',
                },
                percentPosition: true,
                stagger: 30,
                visibleStyle: { transform: 'translateY(0)', opacity: 1 },
                hiddenStyle: { transform: 'translateY(100px)', opacity: 0 },
            });

            var iso = news_container.data('isotope');

            news_container.imagesLoaded( function() {
                lazyLoadInstance.update();
                news_container.isotope( 'option', { itemSelector: '.single-news-wrap' });
                var $items = news_container.find('.single-news-wrap');
                news_container.isotope( 'appended', $items );
            });

            if(pagination.length > 0){
                news_container.infiniteScroll({
                    path: '.next',
                    append: '.single-news-wrap',
                    hideNav: '.pagination-manager',
                    outlayer: iso,
                    history: false
                });

                news_container.on( 'scrollThreshold.infiniteScroll', function( event ) {
                    lazyLoadInstance.update();
                });

                news_container.on( 'load.infiniteScroll', function( event, body, path, response ) {
                    lazyLoadInstance.update();
                });

                news_container.on( 'append.infiniteScroll', function( event, body, path, items, response ) {
                    lazyLoadInstance.update();
                    equalHeights('single-news');
                    news_container.isotope('layout');
                });

                news_container.on( 'last.infiniteScroll', function( event, body, path ) {
                    lazyLoadInstance.update();
                });
            }

            $('.filter-news .button').on( 'click', function() {
                var filterValue = $(this).attr('data-filter');
                news_container.isotope({ filter: (filterValue === 'filter_all') ? "*" : filterValue });
            });

            $('.filter-mobile-news select').on('change', function(){
                var filterValue = $(this).val();
                news_container.isotope({ filter: (filterValue === 'filter_all') ? "*" : filterValue });
            });

        }
    }

    function grand_magic_faq(){
        var faq_wrap = $('.faq-list'),
        faq_list = faq_wrap.find('.single-faq-wrap');
        if(faq_wrap.length > 0){
            faq_list.each(function(){
                var singleQuestion = $(this).find('.single-faq-question'),
                    singleAnswerWrap = $(this).find('.single-faq-answer-wrap'),
                    singleAnswer = $(this).find('.single-faq-answer');
                singleQuestion.on('click', function(){
                    if(!$(this).parent().hasClass('opened')){
                        $(this).parent().addClass('opened');
                        singleAnswerWrap.css({
                            height: singleAnswer.outerHeight(true)
                        });
                        addEventListener("resize", function(){
                            singleAnswerWrap.css({
                                height: singleAnswer.outerHeight(true)
                            });
                        });
                    } else {
                        $(this).parent().removeClass('opened');
                        singleAnswerWrap.css({
                            height: 0
                        });
                    }
                });
            });
        }
    }

    function grand_magic_equalHeights(elements) {
        let maxHeight = 0;

        elements.each(function() {
            maxHeight = Math.max($(this).height(), maxHeight);
        });

        elements.height(maxHeight);

        addEventListener("resize", function(){
            maxHeight = 0;
            elements.each(function() {
                maxHeight = Math.max($(this).height(), maxHeight);
            });

            elements.height(maxHeight);
        });
    }

    function grand_magic_restaurant_cards() {
        var restaurants_cards_section = $('.restaurants-children-section');
        var restaurants_cards_siblings = $('.siblings-restaurant-section');

        if (restaurants_cards_section.length || restaurants_cards_siblings.length) {
            var restaurantTitle = $('.single-restaurant .the-title');
            if (!is_mobile) {
                grand_magic_equalHeights(restaurantTitle);
            }
        }
    }

    function grand_magic_instagram_feeds(){
        if($('body').hasClass('home')){
            var instagram_feeds_container = $('.instagram-feed');
            if(instagram_feeds_container.length > 0){
                var instagram_feeds_section = document.querySelector('.instagram-section');
                observer = new IntersectionObserver(function(entry, observer){
                    if(entry[0].intersectionRatio > 0){
                        $('.instagram-feed').append(JSON.parse($('#instagram_data').text()));
                        sbi_init();
                        observer.unobserve(instagram_feeds_section);
                    }
                });
                observer.observe(instagram_feeds_section);
            }
        }
    }

    function grand_magic_home_fullpage(){
        if($('html').hasClass('home')){
            fullPage = new fullpage('#fullpage', {
                sectionSelector: '.section-block',
                licenseKey: 'zMccI0J!a3',
                navigation: true,
                slidesNavigation: true,
                controlArrows: false,
                lazyLoading: false,
                scrollingSpeed: 1400,
                bigSectionsDestination:'top',
                onLeave: function(origin, destination, direction) {
                    if((origin.anchor === 'video-section' || origin.anchor === 'slideshow-section') && direction ==='down'){
                        gsap.to(".logo-big", {
                            duration: 1,
                            ease: 'none',
                            yPercent: -100,
                            top: "auto",
                            bottom: 100,
                            onComplete: grand_magic_logo_animation_particles_fullpage()
                        });
                    }
                    else if((destination.anchor === 'video-section' || destination.anchor === 'slideshow-section') && direction ==='up'){
                        gsap.to(".logo-big", {
                            duration: 1,
                            ease: 'none',
                            yPercent: 0,
                            top: 0,
                            bottom: "auto"
                        });
                    }
                },
                afterRender: function(){
                    $('.section-block:not(.fp-auto-height).fp-section.fp-table').removeAttr("style");
                }
            });
            if($('html').hasClass('has-loader')){
                if(fullPage){
                    fullpage_api.setAllowScrolling(false);
                    fullpage_api.setKeyboardScrolling(false);
                }

            }
            if(is_mobile || grand_magic_disableFullPage()){
                if(fullPage){
                    fullpage_api.destroy('all');
                }
            }

        }
    }

    function grand_magic_home_moustache_scroll_down(){
        if($('html').hasClass('home')){
            var moustache_godown = $('.moustache-home-godown');
            if(moustache_godown.length > 0){
                moustache_godown.on('click', function(){
                    if($('html').hasClass('fp-enabled')){
                        if(fullPage){
                            fullpage_api.moveSectionDown();
                        }
                    } else {
                        $('html,body').animate({
                                scrollTop: ($(".main-content-section").offset().top - $('.header-container').height())},
                            'slow');
                    }
                });
            }

        }
    }

    function grand_magic_gform_submit_scroll_top(){
        if($('html').hasClass('page-template-template-contacts') || $('html').hasClass('page-template-template-newsletter')){
            jQuery(document).on('gform_confirmation_loaded', function(){
                $('html, body').animate({ scrollTop: 0 }, 'slow');
            });
        }
    }

    function grand_magic_moustache_callback(){
        var call = document.getElementById("call-back");
        if(call){
            gsap.set("#moustache", { rotation: "0deg", transformOrigin: '50% 50%' });
            let animation = gsap.fromTo("#moustache", {
                    repeat: -1,
                    yoyo: false,
                    rotation: "15deg",
                    ease: "power4.inOut",
                    transformOrigin: '50% 50%'
                },
                {
                    duration: .4,
                    repeat: -1,
                    yoyo: true,
                    rotation: "-15deg",
                    ease: "power1.inOut"
                }
            );
            animation.pause(0.2);
            call.addEventListener("mouseenter", function(){
                animation.play(1, true)
            });
            call.addEventListener("mouseleave", function(){
                animation.pause(0.2)
            });
        }

    }

    function grand_magic_sparkle_callback(){
        $(".sparkle, .sbi_follow_btn a span").sparkle({
            "count": 20,
            "minSize": 2,
            "maxSize": 8,
            "overlap": 10,
            "speed": 1,
            "fadeSpeed": 3000
        });
    }

    function grand_magic_plane(){
        var plane = document.getElementById('plane'),
            plane_second = document.getElementById('plane_second');

        if(!is_phone){
            if(plane || plane_second){
                gsap.registerPlugin(MotionPathPlugin);

                if(plane){
                    gsap.to("#plane", {
                        duration: 5,
                        repeat: -1,
                        ease: "none",
                        motionPath:{
                            path: "#line_dotted_big",
                            align: "#line_dotted_big",
                            autoRotate: true,
                            alignOrigin: [0, 0.9]
                        }
                    });
                }

                if(plane_second){
                    gsap.to("#plane_second", {
                        duration: 5,
                        repeat: -1,
                        ease: "power1.inOut",
                        motionPath:{
                            path: "#line_dotted_small",
                            align: "#line_dotted_small",
                            alignOrigin: [.7, 1],
                            start: 0,
                            end: 1
                        }
                    });
                }
            }
        }
    }

    function grand_magic_animated_stars(){
        var stars_wrap = document.getElementById('stars-wrap'),
            stars_advantages = document.getElementById('stars-wrap-advantages'),
            stars_gold_wrap = document.getElementById('stars-gold-wrap'),
            stars_white_wrap = document.getElementById('stars-white-wrap'),
            stars_blue_wrap = document.getElementById('stars-blue-wrap'),
            stars_light_blue_wrap = document.getElementById('stars-light-blue-wrap');

        if(!is_phone){
                if(stars_wrap){
                    gsap.fromTo("#stars-wrap path", {
                            autoAlpha: 0,
                            scale: .2,
                            repeat: -1,
                            yoyo: true,
                            rotation: "90deg",
                            ease: "power4.inOut",
                            transformOrigin: '50% 50%'
                        },
                        {
                            duration: 1,
                            autoAlpha: 1,
                            scale: 1,
                            repeat: -1,
                            yoyo: true,
                            rotation: "190deg",
                            ease: "power4.inOut",
                            stagger: {
                                each: .2,
                                repeat: -1,
                                repeatRefresh: false,
                            }
                        }
                    );
                }

                if(stars_advantages){
                    gsap.to("#stars-wrap-advantages .starr", {
                        autoAlpha: 0,
                        scale: .7,
                        repeat: -1,
                        yoyo: true,
                        ease: "power1.inOut",
                        stagger: {
                            from: "random",
                            amount: 2.5
                        }
                    });
                }

                if(stars_gold_wrap){
                    gsap.fromTo("#stars-gold-wrap path", {
                            autoAlpha: 0,
                            scale: .2,
                            repeat: -1,
                            yoyo: true,
                            rotation: "90deg",
                            ease: "power4.inOut",
                            transformOrigin: '50% 50%'
                        },
                        {
                            duration: 1,
                            autoAlpha: 1,
                            scale: 1,
                            repeat: -1,
                            yoyo: true,
                            rotation: "190deg",
                            ease: "power4.inOut",
                            stagger: {
                                each: .1,
                                repeat: -1,
                                repeatRefresh: false,
                            }
                        }
                    );
                }

                if(stars_white_wrap){
                    gsap.fromTo("#stars-white-wrap path", {
                            autoAlpha: 0,
                            scale: .2,
                            repeat: -1,
                            yoyo: true,
                            rotation: "90deg",
                            ease: "power4.inOut",
                            transformOrigin: '50% 50%'
                        },
                        {
                            duration: 1,
                            autoAlpha: 1,
                            scale: 1,
                            repeat: -1,
                            yoyo: true,
                            rotation: "190deg",
                            ease: "power4.inOut",
                            stagger: {
                                each: .1,
                                repeat: -1,
                                repeatRefresh: false,
                            }
                        }
                    );
                }

                if(stars_blue_wrap){
                    gsap.fromTo("#stars-blue-wrap path", {
                            autoAlpha: 0,
                            scale: .2,
                            repeat: -1,
                            yoyo: true,
                            rotation: "90deg",
                            ease: "power4.inOut",
                            transformOrigin: '50% 50%'
                        },
                        {
                            duration: 1,
                            autoAlpha: 1,
                            scale: 1,
                            repeat: -1,
                            yoyo: true,
                            rotation: "190deg",
                            ease: "power4.inOut",
                            stagger: {
                                each: .1,
                                repeat: -1,
                                repeatRefresh: false,
                            }
                        }
                    );
                }

                if(stars_light_blue_wrap){
                    gsap.fromTo("#stars-light-blue-wrap path", {
                            autoAlpha: 0,
                            scale: .2,
                            repeat: -1,
                            yoyo: true,
                            rotation: "90deg",
                            ease: "power4.inOut",
                            transformOrigin: '50% 50%'
                        },
                        {
                            duration: 1,
                            autoAlpha: 1,
                            scale: 1,
                            repeat: -1,
                            yoyo: true,
                            rotation: "190deg",
                            ease: "power4.inOut",
                            stagger: {
                                each: .1,
                                repeat: -1,
                                repeatRefresh: false,
                            }
                        }
                    );
                }
        }
    }

    function grand_magic_logo_animation_particles(){

        var logo = document.getElementById("logo_default");

        var app = new PIXI.Application({backgroundAlpha: 0, width: 99, height: 99 });
        logo.appendChild(app.view);

        var container = new PIXI.Container();
        app.stage.addChild(container);


        let timestamp = 0;
        let saveTimestamp = 0;


        var emitter = new PIXI.particles.Emitter(

            // The PIXI.Container to put the emitter in
            // if using blend modes, it's important to put this
            // on top of a bitmap, and not use the root stage Container
            container,
            // Emitter configuration, edit this to change the look
            // of the emitter
            {
                alpha: {
                    start: 1,
                    end: 0
                },
                scale: {
                    start: 0.2,
                    end: 0.01,
                    minimumScaleMultiplier: 1
                },
                color: {
                    "start": "#ff0000",
                    "end": "#3bff93"
                },
                speed: {
                    start: 18,
                    end: 1,
                    minimumSpeedMultiplier: 1
                },
                acceleration: {
                    x: 0,
                    y: 30
                },
                maxSpeed: 0,
                startRotation: {
                    min: 0,
                    max: 360
                },
                noRotation: false,
                rotationSpeed: {
                    min: 0,
                    max: 0
                },
                lifetime: {
                    min: 0.1,
                    max: 0.8
                },
                blendMode: "normal",
                frequency: 0.001,
                emitterLifetime: -1,
                maxParticles: 169,
                pos: {
                    x: 0,
                    y: 0
                },
                addAtBack: false,
                behaviors: [
                    {
                        type: 'alpha',
                        config: {
                            alpha: {
                                list: [
                                    {
                                        value: 1,
                                        time: 0
                                    },
                                    {
                                        value: 0,
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'scale',
                        config: {
                            scale: {
                                list: [
                                    {
                                        value: 0.16,
                                        time: 0
                                    },
                                    {
                                        value: 0.005,
                                        time: 0.2
                                    },
                                    {
                                        value: 0.001,
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'color',
                        config: {
                            color: {
                                list: [
                                    {
                                        value: "#feffe3",
                                        time: 0
                                    },
                                    {
                                        value: "#d9ad18",
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'blendMode',
                        config: {
                            blendMode: 'normal',
                        }
                    },
                    {
                        type: 'moveSpeed',
                        config: {
                            speed: {
                                list: [
                                    {
                                        value: 10,
                                        time: 0
                                    },
                                    {
                                        value: 1,
                                        time: 1
                                    }
                                ],
                                isStepped: false
                            },
                        }
                    },
                    {
                        "type": "rotation",
                        "config": {
                            "minStart": 0,
                            "maxStart": 360,
                            "minSpeed": 3,
                            "maxSpeed": 4,
                            "accel": 0
                        }
                    },
                    {
                        type: 'spawnShape',
                        config: {
                            type: 'rect',
                            data: {
                                x: 0,
                                y: 0,
                                w: 10,
                                h: 10
                            }
                        }
                    },
                    {
                        "type": "moveAcceleration",
                        "config": {
                            "accel": {
                                "x": 0,
                                "y": 20
                            },
                            "minStart": 0,
                            "maxStart": 1,
                            "rotate": false
                        }
                    },
                    {
                        type: 'textureSingle',
                        config: {
                            texture: PIXI.Texture.from(grand_magic_images_folder + 'particle.png')
                        }
                    }
                ]
            }
        );

// Calculate the current time
        var elapsed = Date.now();

        function getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent) {
            var x =
                Math.pow(1 - percent, 2) * startPt.x +
                2 * (1 - percent) * percent * controlPt.x +
                Math.pow(percent, 2) * endPt.x;
            var y =
                Math.pow(1 - percent, 2) * startPt.y +
                2 * (1 - percent) * percent * controlPt.y +
                Math.pow(percent, 2) * endPt.y;
            return { x: x, y: y };
        }

        var startPt = { x: 0, y: 0 };
        var controlPt = { x: 0, y: 0 };
        var endPt = { x: 0, y: 99 };

        var percent = 0;

// 2

// circle values matching your path:
        const origin = { x: 300, y: 600 };
        const radius = 99;
        var start = 0.9272951769;
        var end = 2.21429922;

// animation values
        var step = 0;
        var totalSteps = 120;
        var stepSize = (end - start) / totalSteps;

// Update function every frame
        var update = function () {

            percent = (percent + 0.003) % 1;
            var point = getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent);
            var lastPoint = getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent - 0.003);
            var angle = Math.atan2(lastPoint.y - point.y, lastPoint.x - point.x);

            // Update the next frame
            requestAnimationFrame(update);

            var now = Date.now();

            // The emitter requires the elapsed
            // number of seconds since the last update
            emitter.update((now - elapsed) * 0.001);
            // emitter.updateOwnerPos(point.x, point.y)
            elapsed = now;
        };

        var sun = 99,
            sunRadius = sun / 2,
            controllables = {
                movesun: false,
                rotationspeed: 1 / 2 // half a turn every second (timestamps are in milliseconds)
            },
            distancePtoS = 49.5,//distance from planet to sun
            planetRadius = 1;

        var planetAngle = 0.0,// Starting angles
            sunAngle = 0.0,
            lastTimestamp;

        let myDraw;

        function draw(timestamp) {
            // requestAnimationFrame(draw); //immediately ask for next frame
            myDraw = requestAnimationFrame(draw);

            if (!lastTimestamp) {
                saveTimestamp = timestamp;
                lastTimestamp = timestamp;
                return;
            }

            var speed = Math.PI * 2.0 * controllables.rotationspeed / 1000, // convert speed from turns per second to radian per millisec
                timestep = timestamp - lastTimestamp;

            lastTimestamp = timestamp; //we save the stamp

            planetAngle += timestep * speed; //we update the angle depending on the currentspeed and the timestep

            //angle = angle % Math.PI / 2;// this is for better comprehension, Math.cos and sin do the clamping for us
            //  debugger;
            //let's make the sun move!!!
            if (controllables.movesun) {
                sunAngle += timestep * speed;
            }

            var sunx = sunRadius + Math.sin(sunAngle) * distancePtoS / 2;// <- sin of 2 angle gives a beautiful infinity
            var suny = sunRadius + Math.sin(sunAngle) * distancePtoS / 2;

            //x and y don't need to be kept, I made them local to draw()
            var planetx = sunx + Math.cos(planetAngle) * distancePtoS;
            var planety = suny + Math.sin(planetAngle) * distancePtoS;

            if (timestamp < (saveTimestamp + 2000)) {
                timestamp = 0;
                emitter.updateOwnerPos(planetx, planety);
            } else {
                cancelAnimationFrame(myDraw);
                emitter.emit = false;
            }
        }

        function startEverything() {
            timestamp = 0;
            saveTimestamp = 0;
            lastTimestamp = 0;
            percent = 0;
            step = 0;

            // Start emitting
            emitter.emit = true;

            // Start the update
            update();
            draw();
        }

        if(!is_phone){
            ScrollTrigger.create({
                start: 'top -80',
                end: 99999,
                onEnter: startEverything
            });
        }
    }

    function grand_magic_logo_animation_particles_fullpage(){

        var logo = document.getElementById("logo_default");

        var app = new PIXI.Application({backgroundAlpha: 0, width: 99, height: 99 });
        logo.appendChild(app.view);

        var container = new PIXI.Container();
        app.stage.addChild(container);


        let timestamp = 0;
        let saveTimestamp = 0;


        var emitter = new PIXI.particles.Emitter(

            // The PIXI.Container to put the emitter in
            // if using blend modes, it's important to put this
            // on top of a bitmap, and not use the root stage Container
            container,
            // Emitter configuration, edit this to change the look
            // of the emitter
            {
                alpha: {
                    start: 1,
                    end: 0
                },
                scale: {
                    start: 0.2,
                    end: 0.01,
                    minimumScaleMultiplier: 1
                },
                color: {
                    "start": "#ff0000",
                    "end": "#3bff93"
                },
                speed: {
                    start: 18,
                    end: 1,
                    minimumSpeedMultiplier: 1
                },
                acceleration: {
                    x: 0,
                    y: 30
                },
                maxSpeed: 0,
                startRotation: {
                    min: 0,
                    max: 360
                },
                noRotation: false,
                rotationSpeed: {
                    min: 0,
                    max: 0
                },
                lifetime: {
                    min: 0.1,
                    max: 0.8
                },
                blendMode: "normal",
                frequency: 0.001,
                emitterLifetime: -1,
                maxParticles: 169,
                pos: {
                    x: 0,
                    y: 0
                },
                addAtBack: false,
                behaviors: [
                    {
                        type: 'alpha',
                        config: {
                            alpha: {
                                list: [
                                    {
                                        value: 1,
                                        time: 0
                                    },
                                    {
                                        value: 0,
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'scale',
                        config: {
                            scale: {
                                list: [
                                    {
                                        value: 0.16,
                                        time: 0
                                    },
                                    {
                                        value: 0.005,
                                        time: 0.2
                                    },
                                    {
                                        value: 0.001,
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'color',
                        config: {
                            color: {
                                list: [
                                    {
                                        value: "#feffe3",
                                        time: 0
                                    },
                                    {
                                        value: "#d9ad18",
                                        time: 1
                                    }
                                ],
                            },
                        }
                    },
                    {
                        type: 'blendMode',
                        config: {
                            blendMode: 'normal',
                        }
                    },
                    {
                        type: 'moveSpeed',
                        config: {
                            speed: {
                                list: [
                                    {
                                        value: 10,
                                        time: 0
                                    },
                                    {
                                        value: 1,
                                        time: 1
                                    }
                                ],
                                isStepped: false
                            },
                        }
                    },
                    {
                        "type": "rotation",
                        "config": {
                            "minStart": 0,
                            "maxStart": 360,
                            "minSpeed": 3,
                            "maxSpeed": 4,
                            "accel": 0
                        }
                    },
                    {
                        type: 'spawnShape',
                        config: {
                            type: 'rect',
                            data: {
                                x: 0,
                                y: 0,
                                w: 10,
                                h: 10
                            }
                        }
                    },
                    {
                        "type": "moveAcceleration",
                        "config": {
                            "accel": {
                                "x": 0,
                                "y": 20
                            },
                            "minStart": 0,
                            "maxStart": 1,
                            "rotate": false
                        }
                    },
                    {
                        type: 'textureSingle',
                        config: {
                            texture: PIXI.Texture.from(grand_magic_images_folder + 'particle.png')
                        }
                    }
                ]
            }
        );

// Calculate the current time
        var elapsed = Date.now();

        function getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent) {
            var x =
                Math.pow(1 - percent, 2) * startPt.x +
                2 * (1 - percent) * percent * controlPt.x +
                Math.pow(percent, 2) * endPt.x;
            var y =
                Math.pow(1 - percent, 2) * startPt.y +
                2 * (1 - percent) * percent * controlPt.y +
                Math.pow(percent, 2) * endPt.y;
            return { x: x, y: y };
        }

        var startPt = { x: 0, y: 0 };
        var controlPt = { x: 0, y: 0 };
        var endPt = { x: 0, y: 99 };

        var percent = 0;

// 2

// circle values matching your path:
        const origin = { x: 300, y: 600 };
        const radius = 99;
        var start = 0.9272951769;
        var end = 2.21429922;

// animation values
        var step = 0;
        var totalSteps = 120;
        var stepSize = (end - start) / totalSteps;

// Update function every frame
        var update = function () {

            percent = (percent + 0.003) % 1;
            var point = getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent);
            var lastPoint = getQuadraticBezierXYatPercent(startPt, controlPt, endPt, percent - 0.003);
            var angle = Math.atan2(lastPoint.y - point.y, lastPoint.x - point.x);

            // Update the next frame
            requestAnimationFrame(update);

            var now = Date.now();

            // The emitter requires the elapsed
            // number of seconds since the last update
            emitter.update((now - elapsed) * 0.001);
            // emitter.updateOwnerPos(point.x, point.y)
            elapsed = now;
        };

        var sun = 99,
            sunRadius = sun / 2,
            controllables = {
                movesun: false,
                rotationspeed: 1 / 2 // half a turn every second (timestamps are in milliseconds)
            },
            distancePtoS = 49.5,//distance from planet to sun
            planetRadius = 1;

        var planetAngle = 0.0,// Starting angles
            sunAngle = 0.0,
            lastTimestamp;

        let myDraw;

        function draw(timestamp) {
            // requestAnimationFrame(draw); //immediately ask for next frame
            myDraw = requestAnimationFrame(draw);

            if (!lastTimestamp) {
                saveTimestamp = timestamp;
                lastTimestamp = timestamp;
                return;
            }

            var speed = Math.PI * 2.0 * controllables.rotationspeed / 1000, // convert speed from turns per second to radian per millisec
                timestep = timestamp - lastTimestamp;

            lastTimestamp = timestamp; //we save the stamp

            planetAngle += timestep * speed; //we update the angle depending on the currentspeed and the timestep

            //angle = angle % Math.PI / 2;// this is for better comprehension, Math.cos and sin do the clamping for us
            //  debugger;
            //let's make the sun move!!!
            if (controllables.movesun) {
                sunAngle += timestep * speed;
            }

            var sunx = sunRadius + Math.sin(sunAngle) * distancePtoS / 2;// <- sin of 2 angle gives a beautiful infinity
            var suny = sunRadius + Math.sin(sunAngle) * distancePtoS / 2;

            //x and y don't need to be kept, I made them local to draw()
            var planetx = sunx + Math.cos(planetAngle) * distancePtoS;
            var planety = suny + Math.sin(planetAngle) * distancePtoS;

            if (timestamp < (saveTimestamp + 2000)) {
                timestamp = 0;
                emitter.updateOwnerPos(planetx, planety);
            } else {
                cancelAnimationFrame(myDraw);
                emitter.emit = false;
            }
        }

        function startEverything() {
            timestamp = 0;
            saveTimestamp = 0;
            lastTimestamp = 0;
            percent = 0;
            step = 0;

            // Start emitting
            emitter.emit = true;

            // Start the update
            update();
            draw();
        }

        if(!is_phone){
            startEverything();
        }
    }

    function grand_magic_disableFullPage() {
        var getScreenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            disable_full_page = getScreenHeight < 900;
        return disable_full_page;
    }

    function grand_magic_load_third_party_scripts(){
        //Hotels Network
        var script = document.createElement('script');
        script.src = 'https://www.thehotelsnetwork.com/js/loader.js?property_id=1046971&account_key=15F389B2761C289F8699391175C1BF37';
        script.async = true;
        document.head.appendChild(script);

        //Quicktext
        var quick_textscript = document.createElement('script');
        quick_textscript.src = 'https://cdn.quicktext.im/widget.min.js';
        quick_textscript.setAttribute('data-license','8Qa5i-zUca');
        quick_textscript.defer = true;
        document.body.appendChild(quick_textscript);

        if(isLoadedScript('https://cdn.quicktext.im/widget.min.js')){
            var noscript = document.createElement('noscript');
            var tag_a = document.createElement('a');
            tag_a.setAttribute('href', "https://www.quicktext.im/");
            tag_a.setAttribute("title", "Hotel AI Chabot and Guest Communication");
            tag_a.textContent = "Quicktext hotel chatbot";
            noscript.appendChild(tag_a);
            document.body.appendChild(noscript);
        }
    }

    function grand_magic_popup(){
        if(!$('html').hasClass('has-loader')){
            if ($('#popup_wrapper').length > 0) {
                $.fancybox.open({
                    src: '#popup_wrapper',
                    type: 'inline',
                    touch: false,
                    autoFocus: false,
                    afterLoad: function () {
                        lazyLoadInstance.update();
                        if(fullPage){
                            fullpage_api.setAllowScrolling(false);
                            fullpage_api.setKeyboardScrolling(false);
                        }
                    },
                    afterClose: function (){
                        if(fullPage){
                            fullpage_api.setAllowScrolling(true);
                            fullpage_api.setKeyboardScrolling(true);
                        }
                    }
                });
            }
        }
    }

    window.addEventListener("load", function(){
        grand_magic_remove_loader();
        grand_magic_load_third_party_scripts();
        grand_magic_popup();
    });

    //window load
    $(window).on('load',function () {
        grand_magic_refresh_size_queries();
    });
    //window resize
    $(window).resize(function () {
        grand_magic_refresh_size_queries();
        grand_magic_handler_map();
        grand_magic_home_fullpage();
    });

    //Dom ready
    $(document).ready(function () {
        grand_magic_refresh_size_queries();
        grand_magic_header_position();
        grand_magic_header_logo();
        window.onscroll = function () {
            grand_magic_header_position();
        };
        grand_magic_slideshow();
        grand_magic_video_player();
        grand_magic_sidebar_menu();
        grand_magic_dynamic_calendar();
        grand_magic_minigallery_home();
        grand_magic_websdk_offers();
        grand_magic_handler_map();
        grand_magic_room_minigallery();
        grand_magic_siblings_room_carousel();
        grand_magic_manual_fancybox();
        grand_magic_directions_carousel();
        grand_magic_photogallery();
        grand_magic_restaurant_cards();
        grand_magic_faq();
        grand_magic_news_hub();
        grand_magic_instagram_feeds();
        grand_magic_home_moustache_scroll_down();
        grand_magic_home_fullpage();
        grand_magic_gform_submit_scroll_top();
        grand_magic_sparkle_callback();
        grand_magic_moustache_callback();
        grand_magic_plane();
        grand_magic_animated_stars();
        grand_magic_logo_animation_particles();

    });
})(jQuery);